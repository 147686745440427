var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "dataForm", attrs: { model: _vm.formData, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "name",
                label: "奖品名称",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "w360" },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入奖品名称",
                      disabled: _vm.used,
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "position",
                label: "奖品位置",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item w150",
                  attrs: {
                    clearable: "",
                    placeholder: "奖品位置",
                    disabled: _vm.used,
                  },
                  model: {
                    value: _vm.formData.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "position", $$v)
                    },
                    expression: "formData.position",
                  },
                },
                _vm._l(_vm.prizePosition, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("    "),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "top-start",
                    width: "400",
                    trigger: "click",
                    content:
                      "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
                  },
                },
                [
                  _c("el-image", {
                    attrs: {
                      src: "https://oss.kaoyanvip.cn/attach/file1667808853716.png",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "reference", type: "text" },
                      slot: "reference",
                    },
                    [_vm._v("位置示例")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "custom-form-item",
              attrs: {
                label: "奖品类型",
                "label-width": _vm.formLabelWidth,
                required: "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "prize_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item w150",
                      attrs: {
                        clearable: "",
                        placeholder: "奖品类型",
                        disabled: _vm.used,
                      },
                      model: {
                        value: _vm.formData.prize_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "prize_type", $$v)
                        },
                        expression: "formData.prize_type",
                      },
                    },
                    [
                      _c("el-option", {
                        key: 1,
                        attrs: { label: "优惠券", value: 1 },
                      }),
                      _c("el-option", {
                        key: 2,
                        attrs: { label: "课程", value: 2 },
                      }),
                      _c("el-option", {
                        key: 3,
                        attrs: { label: "实物", value: 3 },
                      }),
                      _c("el-option", {
                        key: 4,
                        attrs: { label: "其他", value: 4 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v("    "),
              _vm.prizeChoose
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "prize_data" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            icon: "el-icon-plus",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.tqTableTransfer.handleShow(
                                _vm.formData.prize_type
                              )
                            },
                          },
                        },
                        [_vm._v("选择" + _vm._s(_vm.prizeTypeName))]
                      ),
                      _c("span", { staticClass: "f-grey f13 ml10" }, [
                        _vm._v(
                          "已选择" +
                            _vm._s(_vm.formData.prize_data.length) +
                            "个" +
                            _vm._s(_vm.prizeTypeName)
                        ),
                      ]),
                      _c("TqTableTransfer", {
                        ref: "tqTableTransfer",
                        attrs: {
                          limit: 1,
                          multiple: false,
                          disabled: _vm.used,
                          "produc-type": 2,
                        },
                        model: {
                          value: _vm.formData.prize_data,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "prize_data", $$v)
                          },
                          expression: "formData.prize_data",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "probability",
                label: "中奖概率",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      max: 100,
                      disabled: _vm.used,
                    },
                    model: {
                      value: _vm.formData.probability,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "probability", $$v)
                      },
                      expression: "formData.probability",
                    },
                  }),
                  _c("span", { staticClass: "f-grey f13 ml10" }, [_vm._v("%")]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "total",
                label: "奖品数量",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      max: 999999,
                    },
                    model: {
                      value: _vm.formData.total,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "total", $$v)
                      },
                      expression: "formData.total",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "daily_number",
                label: "每日最多发放量",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      max: 999999,
                      disabled: _vm.used,
                    },
                    model: {
                      value: _vm.formData.daily_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "daily_number", $$v)
                      },
                      expression: "formData.daily_number",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "earliest_winning_time",
                label: "最早中奖时间",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      disabled: _vm.used,
                    },
                    model: {
                      value: _vm.formData.earliest_winning_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "earliest_winning_time", $$v)
                      },
                      expression: "formData.earliest_winning_time",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "image",
                label: "奖品格子图片",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c("TqImgUpload", {
                attrs: {
                  "tip-size": "140px*120px",
                  skin: "small",
                  disabled: _vm.used,
                },
                model: {
                  value: _vm.formData.image,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "image", $$v)
                  },
                  expression: "formData.image",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }